<template>
  <section>
    <div>
      <header>
        <el-row>
          <el-col :md="3" :lg="5">
            <el-button plain @click="goBack"
              ><i class="el-icon-back"> Back</i></el-button
            >
          </el-col>
          <el-col :md="21" :lg="19">
            <el-steps :active="active" finish-status="success" :space="400">
              <el-step title="Upload file"></el-step>
              <el-step title="Map Fields"></el-step>
              <el-step title="Review & Import"></el-step>
            </el-steps>
          </el-col>
        </el-row>
      </header>
      <hr />
      <section
        style="text-align: center"
        v-if="this.active === 0"
        v-loading="tableLoading"
      >
        <el-upload
          class="upload-file-el-upload"
          drag
          name="logo"
          :on-change="uploadExcelfile"
          action
          :show-file-list="false"
          :auto-upload="false"
          accept=".xlsx"
        >
          <div class="upload-file">
            <div class="icon py-2">
              <img
                src="@/assets/img/icons/upload-file.svg"
                alt="Upload File"
                height="40"
                width="100"
              />
            </div>
            <p class="text-dark fs-9 mb-0">Drag &amp; Drop Excel file here</p>
            <p class="fs-9 mb-0" style="color: #babdc2">OR</p>
            <button
              class="match_upload_button mt-0"
              style="padding: 6px 10px !important"
            >
              Import Excel
            </button>
          </div>
        </el-upload>
        <br />
        <div
          class="d-flex flex-row justify-content-center"
          v-if="isExcelFileuploaded === true"
        >
          <div class="uploaded-file-display pl-1 pr-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="green"
              class="bi bi-check2"
              viewBox="0 0 16 16"
              style="font-weight: 600px !important"
            >
              <path
                d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
              />
            </svg>
            <img src="@/assets/img/entity_dots.svg" />
            <img src="@/assets/img/entity_excel.svg" />
            <p style="margin: auto auto auto 5px">
              {{ excelFile.name }}
            </p>
            <div class="flex-grow-1"></div>
            <div style="cursor: pointer">
              <a @click="removeExcelFile">
                <i class="el-icon-circle-close"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section
        v-if="this.active === 1"
        style="text-align: center"
      >
        <el-tabs v-model="activeNameForEntityImport">
          <el-tab-pane label="All Data" name="success">
            <el-scrollbar wrap-style="max-height:55vh;" ref="configureScroll">
              <el-table
                :data="getExcelFileHeaders"
                border
                style="width: 100%"
                v-loading="tableLoading"
              >
                <el-table-column label="Fields in File">
                  <template slot-scope="scope">
                    <p>{{ scope.row.excel_field }}</p>
                  </template>
                </el-table-column>
                <el-table-column label="Form templates">
                  <template slot-scope="scope">
                    <div class="d-flex flex-row">
                      <el-select
                        clearable
                        filterable
                        v-model="scope.row.template_id"
                        placeholder="Select form template"
                        @change="validateSelectedRow(scope.row)"
                      >
                        <el-option
                          v-for="temp in formTemplates"
                          :key="temp._id"
                          :label="temp.name"
                          :value="temp._id"
                        >
                          <div v-if="temp && temp.type == 'STANDARD'">
                            <span>{{ temp.name }}</span>
                            <span
                              style="color: #f754a2; font-weight: 800"
                              class="ml-2"
                              >STANDARD</span
                            >
                          </div>
                          <div v-else>
                            {{ temp.name }}
                          </div>
                        </el-option>
                      </el-select>
                      <i
                        class="el-icon-circle-plus"
                        style="color: #f754a2; margin-left: 10px"
                        @click="ShowTempDialog(scope.$index)"
                      ></i>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Form fields">
                  <template slot-scope="scope">
                    <div class="d-flex flex-row">
                      <el-select
                        v-if="
                          scope.row.template_id.includes('newTemplate') === true
                        "
                        v-model="scope.row.inputType"
                        placeholder="Select field"
                        @change="ValidateInputFields(scope.row, scope.$index)"
                        clearable
                      >
                        <el-option
                          v-for="field in allFormFIelds"
                          :key="field.key"
                          :label="field.name"
                          :value="field.key"
                        >
                          <div class="field-icon-container">
                            <img
                              :src="
                                require('@/assets/img/fields/' + field.img_url)
                              "
                              style="width: 20px; padding-right: 10px"
                            />
                            {{ field.name }}
                          </div>
                        </el-option>
                      </el-select>
                      <el-select
                        v-else
                        v-model="scope.row.template_filed_id"
                        placeholder="Select field"
                        filterable
                        clearable
                      >
                        <el-option
                          v-for="(field, index) in getTemplateFields(scope.row)"
                          :key="field._id + index + field.label"
                          :label="field.label"
                          :value="field.key"
                          :disabled="checkIsFieldSelected(field)"
                        >
                          <div v-if="field && field.inputType == 'ENTITY'">
                            <span>{{ field.label }}</span>
                            <span
                              style="color: #f754a2; font-weight: 800"
                              class="ml-2"
                              >ENTITY</span
                            >
                          </div>
                          <div v-else>
                            {{ field.label }}
                          </div>
                        </el-option>
                      </el-select>
                      <el-select
                        v-if="
                          scope.row.inputType == 'ENTITY' &&
                          scope.row.template_id.includes('newTemplate') === true
                        "
                        v-model="scope.row.entity_id"
                        placeholder="Select entity"
                        filterable
                        class="ml-2"
                      >
                        <el-option
                          v-for="(field, index) in entitiesList"
                          :key="index"
                          :label="field.name"
                          :value="field._id"
                          :disabled="checkIsEntitySelected(field)"
                        >
                        </el-option>
                      </el-select>
                      <div
                        v-else-if="
                          scope.row.inputType == 'ENTITY_VARIABLE' &&
                          scope.row.template_id.includes('newTemplate')
                        "
                      >
                        <el-button
                          @click="ValidateInputFields(scope.row, scope.$index)"
                          size="mini"
                        >
                          configure
                        </el-button>
                      </div>
                      <el-select
                        v-else-if="
                          scope.row.inputType == 'CURRENCY' &&
                          scope.row.template_id.includes('newTemplate') === true
                        "
                        v-model="scope.row.currency_type"
                        placeholder="Select Currency Type"
                      >
                        <el-option
                          v-for="field in currencyTypes"
                          :key="field"
                          :label="field"
                          :value="field"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Is primary field">
                  <template slot-scope="scope">
                    <el-checkbox
                      v-model="scope.row.isPrimaryField"
                      :disabled="checkIsNewTemplate(scope.row)"
                    >
                    </el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column label="Is required field">
                  <template slot-scope="scope">
                    <el-checkbox
                      v-model="scope.row.isRequired"
                      :disabled="checkIsRequiredDisabled(scope.row)"
                    >
                    </el-checkbox>
                  </template>
                </el-table-column>
              </el-table>
            </el-scrollbar>
          </el-tab-pane>
        </el-tabs>
      </section>
      <section v-if="this.active === 2" style="text-align: center">
        <SuccessAndErrorsTable
          :templatesData="templatesData"
          :currentEntity="currentEntity"
          :foundArray="foundArray"
          :successedExcelImportData="successedExcelImportData"
        >
        </SuccessAndErrorsTable>
      </section>
      <hr />
      <footer>
        <div class="footer" style="text-align: right" v-if="this.active === 0">
          <el-button @click="goBack">Cancel</el-button>
          <el-button
            type="danger"
            @click="gotoMappingScreen"
            :disabled="firstNextDisabled"
            >Next</el-button
          >
        </div>
        <div
          class="footer"
          style="text-align: right"
          v-else-if="this.active === 1"
        >
          <el-button @click="goBack">Cancel</el-button>
          <el-button
            type="danger"
            @click="getMappingScreenData"
            :disabled="isSuccessFullyGoToPreview === true"
            >Next</el-button
          >
        </div>
        <div
          class="footer"
          style="text-align: right"
          v-else-if="this.active === 2"
        >
          <el-button @click="goBack">Cancel</el-button>
          <el-button type="danger" @click="importMappedExcelFileData"
            >Import</el-button
          >
        </div>
      </footer>
    </div>
    <dialog-component
      :title="'Create form template'"
      :visible="centerDialogVisible"
      @before-close="centerDialogVisibleDialog"
      :containerWidth="'30%'"
      center
      :isShowFooter="false"
    >
      <div class="popUp-content mt-1">
        <span>
          <p for="tempTitle">Template Title</p>
          <el-input
            placeholder="Enter template name"
            v-model="formTemplateName"
            clearable
            id="tempTitle"
          >
          </el-input>
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="centerDialogVisible = false">Cancel</el-button>
          <el-button
            type="primary"
            @click="addTemplate"
            :disabled="formTemplateName.trim().length === 0"
            class="SaveBtn"
            >Save</el-button
          >
        </span>
      </div>
    </dialog-component>
    <dialog-component
      title="Configure fields"
      :visible.sync="selectRowCountForFieldsVisible"
      width="55%"
      @before-close="selectRowCountForFieldsVisible = false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="configure-fields-upper-container">
        <el-row>
          <el-col
            :span="18"
            class="p-2"
            style="border-right: 1px solid #d7eefc"
          >
            <div class="mb-1">
              <h4>Form columns</h4>
              <p>
                This option will be used to align the column fields in a row
              </p>
              <div>
                <p style="font-weight: 500">Number of columns in a row</p>
                <el-input-number
                  v-model="formColumnValue"
                  :min="1"
                  :max="3"
                ></el-input-number>
              </div>
            </div>
            <div
              class="template-note d-flex flex-column justify-content-center"
            >
              <p class="ml-2">
                Note:Templates have a maximum limit of 3 column fields per row
              </p>
            </div>
          </el-col>
          <el-col :span="6" class="p-2">
            <div
              style="border: 1px solid #d7eefc; text-align: center"
              v-if="this.formColumnValue === 3"
            >
              Form template preview
              <div
                v-for="i in 5"
                :key="i"
                style="border-top: 1px solid #f2f8fc"
              >
                <div class="d-flex flex-row p-2">
                  <div
                    v-for="i in 3"
                    :key="i"
                    class="division"
                    style="width: 30px"
                  ></div>
                </div>
              </div>
            </div>
            <div
              style="border: 1px solid #d7eefc; text-align: center"
              v-else-if="this.formColumnValue === 2"
            >
              Form template preview
              <div
                v-for="i in 5"
                :key="i"
                style="border-top: 1px solid #f2f8fc"
              >
                <div class="d-flex flex-row p-2">
                  <div
                    v-for="i in 2"
                    :key="i"
                    class="division"
                    style="width: 50px"
                  ></div>
                </div>
              </div>
            </div>
            <div
              style="border: 1px solid #d7eefc; text-align: center"
              v-else-if="this.formColumnValue === 1"
            >
              Form template preview
              <div
                v-for="i in 5"
                :key="i"
                style="border-top: 1px solid #f2f8fc"
              >
                <div class="d-flex flex-row p-2">
                  <div
                    v-for="i in 1"
                    :key="i"
                    class="division"
                    style="width: 120px"
                  ></div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <hr />
      <span slot="footer">
        <el-button @click="selectRowCountForFieldsVisible = false"
          >Cancel</el-button
        >
        <el-button
          type="primary"
          @click="setColumnValueForRow"
          :disabled="multiClickDisable"
          >Confirm</el-button
        >
      </span>
    </dialog-component>

    <dialog-component
      :title="'Processing'"
      :visible="importDialogueVisible"
      :containerWidth="getIsMobile ? '100%' : '50%'"
      :isShowFooter="false"
    >
      <div class="text-center popUp-content">
        <img
          src="@/assets/img/importDataLoading.gif"
          class="import-loading-gif"
        />
        <p>Please wait</p>
        <h1>Your data is in progress...</h1>
      </div>
    </dialog-component>
    <dialog-component
      :title="'Processed!'"
      :visible="mappingStatusDialogVisible"
      :containerWidth="getIsMobile ?'100%' : '40%'"
      center
      @before-close="gotoDashboard"
      :isShowFooter="false"
    >
      <div class="text-center popUp-content">
        <img src="@/assets/img/mappingDone.svg" class="import-loading-gif" />
        <h1 class="mb-0.5">The import process has been initiated.</h1>
        <div class="mb-1">
          <span>
            We are currently in the process of importing your Excel file data.
            Sit back and relax;
          </span>
          <span
            >Once the import is complete, we will notify you through
            email.</span
          >
        </div>
        <el-button type="primary" @click="gotoDashboard"> Done </el-button>
      </div>
    </dialog-component>
    <dialog-component
      :title="'Auto Increment Number'"
      :visible="dialogVisible"
      :containerWidth="'70vh'"
      @before-close="handleCloseIncrementDialogClose"
    >
      <div class="mb-1">
        <el-checkbox
          v-model="considerExcelData"
          style="font-weight: 700; font-size: 20px"
          >Consider excel data</el-checkbox
        >
      </div>
      <div v-if="considerExcelData === false">
        <div class="mb-1">
          <label for="incrementPrefix">Prefix</label>
          <el-input
            placeholder="Prefix"
            v-model="increPrefix"
            id="incrementPrefix"
          ></el-input>
        </div>
        <div>
          <label for="incrementStartingNum"
            ><span style="color: red">*</span>Starting Number</label
          >
          <el-input
            placeholder="Starting Number"
            style="margin-bottom: 3vh"
            v-model="increStartingNum"
            type="number"
            id="incrementStartingNum"
          ></el-input>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetIncrementFields">Cancel</el-button>
        <el-button
          type="primary"
          @click="setIncrementProperties"
          :disabled="increStartingNum === null && considerExcelData === false"
          >Confirm</el-button
        >
      </span>
    </dialog-component>
    <dialog-component
      :title="entityVariableField.excel_field"
      :visible="entityVarDialogVisible"
      :containerWidth="'30%'"
      @before-close="entityVarDialogVisible = false"
    >
      <div class="entity-variable-container mt-1 mb-10">
        <div>
          <label for="valueOfEntity">Entities</label><br />
          <el-select
            v-model="entityVariableField['relationship_key']"
            placeholder="Select Entity"
            id="valueOfEntity"
            style="width: 90%"
            @change="getSelectedEntityFieldValue"
            clearable
          >
            <el-option
              v-for="(entityField, index) in currentTemplateEntityFields"
              :key="entityField.excel_field + index"
              :label="entityField.excel_field"
              :value="entityField.entity_id + '#' + entityField.excel_field"
            >
              <span
                >{{ entityField.excel_field
                }}<span style="color: #8492a4; font-weight: 700">
                  Template variable</span
                ></span
              >
            </el-option>
          </el-select>
        </div>
        <br />
        <div>
          <label for="varibleValueOfEntity">Entity Variable</label><br />
          <el-select
            v-model="entityVariableField['entityVariableInfo']"
            placeholder="Select Variable"
            id="varibleValueOfEntity"
            style="width: 90%"
            clearable
          >
            <el-option
              v-for="(field, index) in selectedEntityAllFields"
              :key="field.key + index"
              :label="field.template_name + '->' + field.label"
              :value="
                field.template_id + '#' + field.key + '#' + field.input_type
              "
            >
            </el-option>
          </el-select>
        </div>
        <br />
        <div>
          <label for="fieldAssignable">Is this field ?</label>
          <el-radio-group
            id="fieldAssignable"
            v-model="entityVariableField['field_assignable']"
          >
            <el-radio label="read_only">Read only</el-radio>
            <el-radio label="editable">Editable</el-radio>
            <el-radio label="update_parent">Update parent</el-radio>
          </el-radio-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="entityVarDialogVisible = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="setPropertiesForEntityVariable"
          :disabled="disableEntityVariableButton"
          >Confirm</el-button
        >
      </span>
    </dialog-component>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import * as XLSX from "xlsx";
import SuccessAndErrorsTable from "./SuccessAndErrorsTable.vue";
import templateBuilderHelper from "../../mixins/templateBuilderHelper";
export default {
  name: "createEtityFromExcel",
  components: {
    SuccessAndErrorsTable,
  },
  mixins: [templateBuilderHelper],
  data() {
    return {
      entityVarDialogVisible: false,
      entityFieldValue: "",
      entityVarValue: "",
      selectedEntityAllFields: [],
      currentTemplateEntityFields: [],
      considerExcelData: false,
      startingNum: "",
      increPrefix: "",
      increStartingNum: null,
      active: 0,
      isExcelFileuploaded: false,
      activeNameForEntityImport: "success",
      centerDialogVisible: false,
      formTemplateName: "",
      allFormFIelds: [
        {
          key: "SINGLE_LINE_TEXT",
          name: "Single Line Text",
          img_url: "SingleLine.svg",
        },
        {
          key: "MULTI_LINE_TEXT",
          name: "Multi Line Text",
          img_url: "MultiLine.svg",
        },
        {
          key: "SELECT",
          name: "Select",
          img_url: "Select.svg",
        },
        {
          key: "NUMBER",
          name: "Number",
          img_url: "Number.svg",
        },
        {
          key: "MULTI_SELECT",
          name: "Multiple Select",
          img_url: "MultiSelect.svg",
        },
        {
          key: "DATE",
          name: "Date",
          img_url: "Date.svg",
        },
        {
          key: "TIME",
          name: "Time",
          img_url: "Date.svg",
        },
        {
          key: "DATE_TIME",
          name: "Date Time",
          img_url: "Date.svg",
        },
        {
          key: "YES_OR_NO",
          name: "Yes or No",
          img_url: "YesNo.svg",
        },

        {
          key: "CHECKBOX",
          name: "Checkbox",
          img_url: "CheckBox.svg",
        },
        {
          key: "RADIO",
          name: "Radio",
          img_url: "CheckBox.svg",
        },
        {
          key: "PHONE_COUNTRY_CODE",
          name: "Phone",
          img_url: "Number.svg",
        },
        {
          key: "CURRENCY",
          name: "Currency",
          img_url: "Number.svg",
        },
        {
          key: "IMAGE",
          name: "Image",
          img_url: "File.svg",
        },
        {
          key: "AUTO_INCREMENT_NUMBER",
          name: "Auto Increment Number",
          img_url: "SingleLine.svg",
        },
        {
          key: "ENTITY",
          name: "Entity",
          img_url: "glodal_variable.svg",
        },
        {
          key: "ENTITY_VARIABLE",
          name: "Entity Variable",
          img_url: "glodal_variable.svg",
        },
      ],
      currencyTypes: ["USD", "CAD", "AUD", "INR", "EUR"],
      existedTemplateFields: [],
      formTemplates: [],
      newFormTempCount: 0,
      isExistedTemplate: false,
      newlyCreatedTemplates: [],
      rowIndex: -1,
      selectRowCountForFieldsVisible: false,
      formColumnValue: 3,
      currentEntityAllFields: [],
      mappedData: [],
      tableLoading: false,
      currentPageForPreviewData: 1,
      pageSizeForPreviewData: 10,
      importDialogueVisible: false,
      mappingStatusDialogVisible: false,
      mappedFieldsRows: [],
      isSuccessFullyGoToPreview: false,
      entitiesList: [],
      trimmedData: [],
      jsonDataOfExcel: [],
      currentEntity: {},
      foundArray: [],
      templatesData: [],
      successedExcelImportData: [],
      excelfilecolumnNames: [],
      dialogVisible: false,
      incrementFieldRowIndex: -1,
      multiClickDisable: false,
      firstNextDisabled: false,
      entityVariableField: {},
      getAllCompanyTemplatesData: [],
    };
  },
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    ...mapGetters("entities", [
      "getEntityDataById",
      "getstoreUploadedFileStatus",
      "getExcelFileHeaders",
      "getEntityCreatedData",
      "getmappedFieldsData",
      "getCreateEntityRecordWithMappedData",
      "getAllEntities",
      "getimportMappedDataStatusUpdatedVersion",
      "gettemplatesByMappedFields",
    ]),
    ...mapGetters("templatesData", ["getAutoIncrementNumber"]),
    ...mapGetters("companyTemplates", [
      // "getAllCompanyTemplatesData",
      "getCreateFormTemplatesFromExcel",
      "getSingleCompanyTemplatesData",
    ]),
    ...mapGetters("entityRelationships", ["getEntityRelationshipsAddStatus"]),
    previewDataPagination() {
      const from =
        (this.currentPageForPreviewData - 1) * this.pageSizeForPreviewData;
      let to = from + this.pageSizeForPreviewData;
      // if (to > this.totalForIEDS) {
      //   to = this.totalForIEDS;
      // }
      return this.trimmedData.slice(from, to);
    },
    disableEntityVariableButton() {
      if (
        this.entityVariableField.relationship_key == "" ||
        this.entityVariableField.entityVariableInfo == ""
      ) {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    await this.getCurrentEntity(this.$route.params.entity_id);
    await this.getAllExistedTemplates();
    await this.getAllEntitiesList();
  },
  methods: {
    centerDialogVisibleDialog() {
      this.centerDialogVisible = false;
    },
    handleCloseIncrementDialogClose() {
      this.dialogVisible = false;
    },
    async getCurrentEntity(entityId) {
      if (entityId) {
        await this.$store.dispatch("entities/fetchEntityById", {
          entity_id: entityId,
        });
        this.formTemplates = [];
      }
    },
    async getAllExistedTemplates() {
      // let params = {
      //   get_all: true,
      //   include_standard: true,
      // };
      // await this.$store.dispatch(
      //   "companyTemplates/getAllCompanyTemplates",
      //   params
      // );
      // if (
      //   this.getAllCompanyTemplatesData
      // ) {
      //   this.getAllCompanyTemplatesData.forEach((formTemp) => {
      //     if (formTemp.is_repeatable === false) {
      //       this.formTemplates.push(formTemp);
      //     }
      //   });
      // }
      this.getAllCompanyTemplatesData = await this.fetchAllTemplates(true);
      this.formTemplates = this.getAllCompanyTemplatesData.filter(
        (temp) => !temp.is_repeatable
      );
      if (this.getEntityDataById.entity_type !== "INDIVIDUAL") {
        this.formTemplates = this.formTemplates.filter(
          (e) => e.type != "STANDARD"
        );
      }
    },
    async getAllEntitiesList() {
      // let params = {
      //   get_all: true,
      // };
      // await this.$store.dispatch("entities/fetchEntities", params);
      // if (this.getAllEntities && this.getAllEntities.data) {
      //   this.getAllEntities.data.forEach((entity) => {
      //     if (this.getEntityDataById._id.toString() !== entity._id.toString()) {
      //       this.entitiesList.push(entity);
      //     }
      //   });
      // }
      this.entitiesList = await this.fetchAllEntities(false);
      this.entitiesList = this.entitiesList.filter(
        (ent) => ent._id.toString() !== this.getEntityDataById._id.toString()
      );
    },
    goBack() {
      this.$router.go(-1);
    },
    uploadExcelfile(file) {
      this.excelFile = file;
      let fileNameArray = this.excelFile.name.split(".");
      fileNameArray.splice(fileNameArray.length - 1, 1);
      this.excelFile.name = fileNameArray.join(".");
      //to read a file using reader
      this.isExcelFileuploaded = true;
      this.handleFileUpload(this.excelFile);
    },
    handleFileUpload(fileData) {
      const file = fileData.raw;
      const reader = new FileReader();
      reader.onload = (e) => {
        //const data = new Uint8Array(e.target.result);
        const data = Buffer.from(e.target.result);
        const workbook = XLSX.read(data, { type: "array", cellDates: true });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        this.jsonDataOfExcel = this.convertDataToJSON(jsonData);
        this.jsonDataOfExcel.map((e) => {
          Object.keys(e).map((f) => {
            if (moment.isDate(e[f])) {
              e[f] = e[f].setHours(e[f].getHours() + 12);
              e[f] = new Date(e[f]);
            }
          });
        });
      };
      reader.onerror = (e) => {
        console.error("handleFileUpload", e);
      };
      reader.readAsArrayBuffer(file);
    },
    convertDataToJSON(data) {
      const headers = data[0];
      this.excelfilecolumnNames = [...data[0]];
      const jsonData = [];
      for (let i = 1; i < data.length; i++) {
        const row = data[i];
        const item = {};
        let allKeys = [];
        for (let j = 0; j < headers.length; j++) {
          if (
            headers[j] &&
            headers[j].includes(".") &&
            headers[j].split(".")[1] !== ""
          ) {
            headers[j] = headers[j].split(".")[1];
          }
          if (headers[j] && headers[j] !== undefined) {
            const value = row[j] !== null ? row[j] : "";
            let itemKeys = Object.keys(item);
            allKeys.push(headers[j]);
            if (
              itemKeys &&
              itemKeys.length > 0 &&
              itemKeys.includes(headers[j])
            ) {
              let count =
                allKeys.filter((element) => element === headers[j]).length - 1;
              headers[j] = headers[j] + "_" + count;
              item[headers[j]] = value;
            } else {
              item[headers[j]] = value;
            }
          }
        }
        jsonData.push(item);
      }
      return jsonData;
    },

    async gotoMappingScreen() {
      if (this.isExcelFileuploaded === true) {
        this.firstNextDisabled = true;
        this.firstNextDisabled = true;
        this.tableLoading = true;
        let fileName = this.excelFile.name;
        var formData = new FormData();
        formData.append(
          "file",
          this.excelFile.raw !== undefined ? this.excelFile.raw : this.excelFile
        );
        let params = {
          id: this.getEntityDataById._id,
          data: formData,
          fileName: fileName ? fileName : "",
        };
        await this.$store.dispatch("entities/storeUploadedFile", params);
        if (this.getstoreUploadedFileStatus) {
          let params = {
            fileName: this.getstoreUploadedFileStatus,
            id: this.getEntityDataById._id,
            excelHeaders: this.excelfilecolumnNames,
          };
          await this.$store.dispatch("entities/fetchExcelFileHeaders", params);
          if (this.getExcelFileHeaders) {
            this.getExcelFileHeaders.forEach((header, index) => {
              if (
                header &&
                header.templateName &&
                header.templateName.length > 0
              ) {
                let tempName = header.templateName;
                if (tempName !== this.formTemplateName) {
                  this.formTemplateName = header.templateName;
                  this.rowIndex = index;
                  this.addTemplate();
                }
              }
            });
          }
        }
        this.tableLoading = false;
        this.active = this.active + 1;
      }
    },
    ShowTempDialog(index) {
      this.rowIndex = index;
      this.formTemplateName = "";
      this.centerDialogVisible = true;
    },
    addTemplate() {
      if (this.formTemplateName) {
        if (this.formTemplates) {
          this.newFormTempCount++;
          let newTemp = {
            _id: "newTemplate" + this.newFormTempCount,
            name: this.formTemplateName,
          };
          let isExisted = this.formTemplates.find((temp) => {
            if (
              temp.name &&
              newTemp.name &&
              temp.name.toLowerCase() === newTemp.name.toLowerCase()
            ) {
              return true;
            }
          });
          if (isExisted) {
            this.newFormTempCount--;
            this.$notify.error({
              title: "Error",
              message: "Template name is already existed",
            });
          } else {
            this.formTemplates.push(newTemp);
            if (this.getEntityDataById.entity_type === "INDIVIDUAL") {
              for (
                let i = this.rowIndex;
                i < this.getExcelFileHeaders.length;
                i++
              ) {
                if (this.getExcelFileHeaders[i].template_id.length > 0) {
                  continue;
                } else {
                  this.getExcelFileHeaders[i].template_id = newTemp._id;
                }
              }
            } else {
              for (
                let i = this.rowIndex;
                i < this.getExcelFileHeaders.length;
                i++
              ) {
                this.getExcelFileHeaders[i].template_id = newTemp._id;
              }
            }
            this.centerDialogVisible = false;
          }
        } else {
          this.newFormTempCount++;
          let newTemp = {
            _id: "newTemplate" + this.newFormTempCount,
            name: this.formTemplateName,
          };
          this.formTemplates.push(newTemp);
          this.centerDialogVisible = false;
        }
      } else {
        return;
      }
    },
    validateSelectedRow(row) {
      if (row.inputType === "ENTITY") {
        row.entity_id = "";
        row.inputType = "";
      }
      row.template_filed_id = "";
    },
    getTemplateFields(row) {
      if (row && row.template_id && this.getAllCompanyTemplatesData) {
        let foundedTemp = this.getAllCompanyTemplatesData.find((temp) => {
          if (
            temp &&
            temp._id &&
            row.template_id &&
            temp._id == row.template_id
          ) {
            return true;
          } else {
            return false;
          }
        });
        let contentFields = [
          "HEADING",
          "PARAGRAPH",
          "HTML_CONTENT",
          "SINGLE_LINE_CONTENT",
          "VIDEO",
          "ACTION_BUTTON",
          "HORIZONTAL_LINE",
          "DIVISION",
        ];
        if (
          foundedTemp &&
          foundedTemp.sections[0] &&
          foundedTemp.sections[0].fields
        ) {
          let fields = [];
          foundedTemp.sections[0].fields.forEach((field) => {
            if (
              field &&
              field.input_type &&
              contentFields.includes(field.input_type) === false
            ) {
              fields.push(field);
            }
          });

          return fields.map((e) => {
            e.template_id = row.template_id;
            return e;
          });
        } else {
          return [];
        }
      }
      return [];
    },
    async getMappingScreenData() {
      this.mappedFieldsRows = JSON.parse(
        JSON.stringify(this.getExcelFileHeaders)
      );
      if (this.getEntityDataById.entity_type === "INDIVIDUAL") {
        let standardTemp = this.getAllCompanyTemplatesData.find((temp) => {
          if (temp && temp.type === "STANDARD") {
            return true;
          }
        });
        let errorMessage = "",
          foundObj = { first_name: false, last_name: false, email: false };
        this.mappedFieldsRows.forEach((fie) => {
          if (fie && fie.template_id && fie.template_id === standardTemp._id) {
            if (fie.template_filed_id == "first_name") {
              foundObj.first_name = true;
            } else if (
              fie.template_filed_id !== "first_name" &&
              fie.template_filed_id == "name"
            ) {
              foundObj.first_name = true;
            }
            if (fie.template_filed_id == "last_name") {
              foundObj.last_name = true;
            } else if (
              fie.template_filed_id !== "last_name" &&
              fie.template_filed_id == "name"
            ) {
              foundObj.last_name = true;
            }
            if (fie.template_filed_id == "email") {
              foundObj.email = true;
            }
          }
        });
        errorMessage =
          (!foundObj.first_name ? "First name" : "") +
          (!foundObj.last_name ? " last name" : "") +
          (!foundObj.email ? " email" : "");
        if (errorMessage) {
          errorMessage = errorMessage + " required";
          this.$notify.error({
            title: "Error",
            message: errorMessage,
          });
          return;
        }
      }
      let fieldCount = 0;
      this.mappedFieldsRows.forEach((row) => {
        if (row && row.template_id == "") {
          fieldCount++;
        }
      });
      if (fieldCount == this.mappedFieldsRows.length) {
        this.$notify.error({
          title: "Error",
          message: "Atleast select one field",
        });
        return;
      }
      //check primaryfields count
      let primaryFieldsCount = 0;
      let hasNewTemp = false;
      await this.mappedFieldsRows.forEach((fie) => {
        if (fie.template_id?.includes("newTemplate")) hasNewTemp = true;
        if (
          fie.inputType !== "" &&
          fie.template_id !== "" &&
          fie.isPrimaryField === true
        ) {
          primaryFieldsCount = primaryFieldsCount + 1;
        }
      });
      let entityVariablesFilled = false;
      this.getExcelFileHeaders.map((e) => {
        if (e.inputType == "ENTITY_VARIABLE") {
          if (e.entityVariableInfo == "" || e.relationship_key == "") {
            entityVariablesFilled = true;
            return;
          }
        }
      });
      if (entityVariablesFilled) {
        this.$notify({
          title: "Error",
          message: "Please configure all the Entity variables information",
          type: "error",
        });
        return;
      }
      if (hasNewTemp && !primaryFieldsCount) {
        return this.$notify({
          title: "Error",
          message:
            "Please select at least one primary field for the new template",
          type: "error",
        });
      }
      //find new templates
      if (this.mappedFieldsRows) {
        this.mappedFieldsRows.forEach((header) => {
          if (
            header &&
            header.template_id &&
            header.template_id.includes("newTemplate")
          ) {
            let newTemplate = this.formTemplates.find((temp) => {
              if (temp._id === header.template_id) {
                return true;
              }
            });
            //newlyCreatedTemplates
            if (newTemplate) {
              if (this.newlyCreatedTemplates) {
                if (
                  header.inputType == "AUTO_INCREMENT_NUMBER" &&
                  header.increPrefix === "" &&
                  header.increStartingNum === null &&
                  header.considerExcelData === true
                ) {
                  let autoInfo = this.findPrefixAndNumber(header);
                  header.increPrefix = autoInfo[0];
                  header.increStartingNum = autoInfo[1];
                }
                let field = {
                  label: header.excel_field,
                  inputType: header.inputType,
                  isPrimaryField: header.isPrimaryField,
                  field_assignable: header.field_assignable,
                  isRequired: header.isRequired || false,
                  ...(header.inputType == "ENTITY" &&
                    header.entity_id.length > 0 && {
                      entity_id: header.entity_id,
                    }),
                  ...(header.inputType == "CURRENCY" &&
                    header.currency_type.length > 0 && {
                      currency_type: header.currency_type,
                    }),
                  ...(header.inputType == "AUTO_INCREMENT_NUMBER" &&
                    // header.increPrefix &&
                    // header.increPrefix.length > 0 &&
                    header.increStartingNum != null && {
                      auto_increment_prefix: header.increPrefix,
                      auto_increment_starting_number: header.increStartingNum,
                    }),
                  // ...(header.input_type && header.inputType==='ENTITY_VARIABLE' && header.global_variable_entity_field && header.global_variable_entity_field_template_id && header.global_variable_entity_parent && header.global_variable_entity_select_type_field_key && header.relationship_key && header.selected_enity_field_data && {
                  //   input_type:header.input_type,
                  //   global_variable_entity_field:header.global_variable_entity_field,
                  //   global_variable_entity_field_template_id:header.global_variable_entity_field_template_id,
                  //   global_variable_entity_parent:header.global_variable_entity_parent,
                  //   global_variable_entity_select_type_field_key:header.global_variable_entity_select_type_field_key,
                  //   relationship_key:header.relationship_key,
                  //   selected_enity_field_data:header.selected_enity_field_data
                  // }
                  //)
                  ...(header.inputType == "ENTITY_VARIABLE" &&
                    header.entityVariableInfo !== "" &&
                    header.relationship_key !== "" && {
                      entityVariableInfo: header.entityVariableInfo,
                      relationship_key: header.relationship_key,
                    }),
                };
                let tempIndex = this.newlyCreatedTemplates.findIndex((te) => {
                  if (
                    te &&
                    te._id &&
                    newTemplate._id &&
                    te._id === newTemplate._id
                  ) {
                    return true;
                  }
                });
                if (tempIndex === -1) {
                  newTemplate.fields = [];
                  newTemplate.fields.push(field);
                  this.newlyCreatedTemplates.push(newTemplate);
                } else {
                  let a = this.newlyCreatedTemplates[tempIndex];
                  a.fields = [...a.fields, ...[field]];
                  this.newlyCreatedTemplates[tempIndex] = a;
                }
              } else {
                let field = {
                  label: header.excel_field,
                  inputType: header.inputType,
                  isPrimaryField: header.isPrimaryField,
                  isRequired: header.isRequired || false,
                  entity_id: header.entity_id,
                };
                newTemplate.fields = [];
                newTemplate.fields.push(field);
                this.newlyCreatedTemplates.push(newTemplate);
              }
            }
          }
        });
      }
      if (this.newlyCreatedTemplates.length > 0) {
        this.selectRowCountForFieldsVisible = true;
      } else {
        this.tableLoading = true;
        this.addTemplatesToTheEntity();
      }
    },
    findPrefixAndNumber(header) {
      let minValue = 99999999;
      let prefix = "";
      this.jsonDataOfExcel.map((data) => {
        let key = Object.keys(data).find((key) => {
          if (key && key === header.excel_field) {
            return true;
          }
        });
        if (key) {
          key = key.toString();
          let arr = this.findValidDataWithSeperator(data[key]);
          if (parseInt(arr[1]) < minValue) {
            minValue = parseInt(arr[1]);
            prefix = arr[0];
          }
        }
      });
      return [prefix, minValue];
    },
    findValidDataWithSeperator(key) {
      key = key.toString();
      if (key.includes("-")) {
        let arr = key.split("-");
        return [arr[0], arr[1]];
      } else if (key.includes("_")) {
        let arr = key.split("_");
        return [arr[0], arr[1]];
      } else if (key.includes("/")) {
        let arr = key.split("/");
        return [arr[0], arr[1]];
      } else if (key.includes("#")) {
        let arr = key.split("#");
        return [arr[0], arr[1]];
      } else {
        return ["", null];
      }
    },
    checkIsFieldSelected(field) {
      return this.getExcelFileHeaders.find(
        (e) =>
          e.template_id == field.template_id && e.template_filed_id == field.key
      )
        ? true
        : false;
    },
    checkIsEntitySelected(entity) {
      return this.getExcelFileHeaders.find(
        (e) =>
          e.entity_id.toString() == entity._id.toString() &&
          e.inputType == "ENTITY"
      )
        ? true
        : false;
    },
    async setColumnValueForRow() {
      // let validNewlyCreatedTemplates=[];
      this.tableLoading = true;
      this.multiClickDisable = true;
      this.newlyCreatedTemplates.forEach((temp) => {
        if (temp && temp._id && temp.fields.length > 0) {
          let filteredFields = temp.fields.filter((fie) => {
            if (fie && fie.inputType !== "") {
              return true;
            }
          });
          temp.fields = filteredFields;
        }
      });
      this.newlyCreatedTemplates.forEach((temp) => {
        let fieldLabels = [];
        if (temp && temp.fields) {
          temp.fields.forEach((fie) => {
            if (fieldLabels.length > 0 && fieldLabels.includes(fie.label)) {
              fieldLabels.push(fie.label);
              let elementsCount = fieldLabels.reduce((count, element) => {
                count[element] = (count[element] || 0) + 1;
                return count;
              }, {});
              fie.fieldCount = elementsCount[fie.label] - 1;
            } else {
              fie.fieldCount = 0;
              fieldLabels.push(fie.label);
            }
          });
        }
        temp.slug = temp.name;
        temp.created_by = this.getEntityDataById.created_by;
        temp.company = this.getEntityDataById.company_id;
        temp.templateColumnValue = this.formColumnValue;
        temp.filePath = this.getstoreUploadedFileStatus;
      });
      let params = {
        templatesData: this.newlyCreatedTemplates,
      };
      this.selectRowCountForFieldsVisible = false;
      await this.$store.dispatch(
        "companyTemplates/createTemplateFromExcel",
        params
      );
      if (
        this.getCreateFormTemplatesFromExcel &&
        this.getCreateFormTemplatesFromExcel.data
      ) {
        this.$notify.success({
          title: "Success",
          message: "Templates created successfully",
        });
        //replace template_ids in getExcelFileHeaders
        this.getCreateFormTemplatesFromExcel.data.forEach((newTemp) => {
          let foundedTemp = this.newlyCreatedTemplates.find((tf) => {
            if (newTemp && tf && newTemp.name === tf.name) {
              return true;
            }
          });
          if (foundedTemp) {
            this.mappedFieldsRows.forEach((header) => {
              if (
                header &&
                header.template_id &&
                header.template_id === foundedTemp._id
              ) {
                header.template_id = newTemp._id;
              }
            });
          }
        });
        //bring all the templates including newly added templates
        this.addTemplatesToTheEntity();
      } else {
        this.$notify.error({
          title: "Error",
          message: "Error in creating templates",
        });
        this.newlyCreatedTemplates.forEach((temp) => {
          if (temp && temp._id && temp.fields) {
            temp.fields = [];
          }
        });
      }
    },
    async addTemplatesToTheEntity() {
      this.isSuccessFullyGoToPreview = true;
      //to get only selected templates
      let tempParams = {
        mappedFieldsRows: this.mappedFieldsRows,
        company_id: this.getEntityDataById.company_id,
        fileName: this.getstoreUploadedFileStatus,
      };
      await this.$store.dispatch(
        "entities/getTemplatesByMappedFields",
        tempParams
      );
      this.currentEntityAllFields = [];
      if (
        this.getEntityDataById.entity_type === "INDIVIDUAL" &&
        this.gettemplatesByMappedFields &&
        this.gettemplatesByMappedFields.data
      ) {
        let standard_temp;
        let standard_temp_index;
        this.gettemplatesByMappedFields.data.forEach((temp, index) => {
          if (temp && temp.type === "STANDARD") {
            standard_temp = temp;
            standard_temp_index = index;
          }
        });
        if (standard_temp && standard_temp_index) {
          this.gettemplatesByMappedFields.data.splice(standard_temp_index, 1);
          this.gettemplatesByMappedFields.data.unshift(standard_temp);
        }
      }
      this.gettemplatesByMappedFields.data.forEach((temp, index) => {
        if (temp && temp.sections[0] && temp.sections[0].fields) {
          temp.sections[0].fields.forEach((field) => {
            if (field) {
              field.template_id = temp._id;
              this.currentEntityAllFields.push(field);
            }
          });
        }
        temp.template_order = index;
        temp.template_id = temp._id;
      });
      //fill template filed id
      this.mappedFieldsRows.map((header) => {
        if (header && header.excel_field && header.template_id) {
          let foundedField = this.currentEntityAllFields.find((fie) => {
            if (
              fie &&
              fie.template_id &&
              fie.label &&
              fie.label === header.excel_field &&
              fie.template_id === header.template_id
            ) {
              return true;
            }
          });
          if (foundedField) {
            header.inputType = foundedField.inputType;
            if (header.fieldRepeatedCount > 0) {
              header.template_filed_id =
                foundedField.key + "_" + header.fieldRepeatedCount;
            } else {
              header.template_filed_id = foundedField.key;
            }
          }
        }
      });
      //find entity primary fields
      let entityPrimaryFields = [];
      this.mappedFieldsRows.forEach((head) => {
        if (
          head &&
          head.template_id &&
          head.template_filed_id &&
          head.isPrimaryField === true
        ) {
          let foundedField = this.currentEntityAllFields.find((fie) => {
            if (
              fie &&
              fie.template_id &&
              fie.key &&
              head.template_id === fie.template_id &&
              head.template_filed_id === fie.key
            ) {
              return true;
            }
          });
          if (foundedField) {
            entityPrimaryFields.push(foundedField);
          }
        }
      });
      if (
        this.gettemplatesByMappedFields.data &&
        this.gettemplatesByMappedFields.data.length > 0
      ) {
        let params = {
          name: this.getEntityDataById.name.trim(),
          description: this.getEntityDataById.description,
          entity_type: this.getEntityDataById.entity_type,
          id: this.getEntityDataById._id,
          templates: this.gettemplatesByMappedFields.data,
          primaryFields: entityPrimaryFields,
          entityGroups: this.getEntityDataById.entity_groups,
          has_login: this.getEntityDataById.has_login,
          populate: true,
        };
        await this.$store.dispatch("entities/createNewEntity", params);
        if (this.getEntityCreatedData) {
          this.currentEntity = this.getEntityCreatedData;
          if (this.jsonDataOfExcel) {
            this.trimmedData = this.jsonDataOfExcel.map((e) => {
              let obj = {};
              Object.keys(e).forEach((fie) => {
                this.currentEntityAllFields.forEach((field) => {
                  if (
                    field.key.toString() == fie.toString() &&
                    field.inputType == "DATE"
                  ) {
                    e[fie] = moment(e[fie])
                      .add(10, "seconds")
                      .format("YYYY-MM-DD");
                  } else if (
                    field.key.toString() == fie.toString() &&
                    field.inputType == "TIME"
                  ) {
                    // e[fie] = this.convertTimeToDate(e[fie])
                  }
                });
                obj[fie.trim()] = e[fie];
              });
              return obj;
            });
            this.mappedData = [];
            this.mappedFieldsRows.forEach((head) => {
              if (head && head.template_id && head.template_filed_id) {
                this.mappedData.push(head);
              }
            });
            let fieldsArray = [];

            this.mappedData.map((e) => {
              this.currentEntity.templates.map((f) => {
                f.template_id.sections[0].fields.map((g) => {
                  if (
                    e.template_filed_id == g.key &&
                    e.template_id == f.template_id._id
                  ) {
                    if (e && e.inputType === "AUTO_INCREMENT_NUMBER") {
                      g.considerExcelData = e.considerExcelData;
                    }
                    g["template_id"] = e.template_id;
                    fieldsArray.push(g);
                  }
                });
              });
            });
            this.foundArray = fieldsArray;
            let tempData = [];
            let isFirstIterationForIncrement = true;
            this.trimmedData.map((data) => {
              let rowObj = {};
              Object.keys(data).map((e) => {
                this.mappedData.map((f) => {
                  if (f.excel_field.toString().trim() == e.toString().trim()) {
                    let obj = {};
                    if (
                      f.inputType == "MULTI_SELECT" &&
                      data[e] !== "" &&
                      data[e] !== undefined
                    ) {
                      data[e] = data[e]?.toString();
                      if (data[e].includes(",")) {
                        data[e] = data[e].split(",");
                      }
                      // data[e] = data[e].split(",");
                    } else if (f.inputType == "DATE") {
                      data[e] = moment(data[e]).add(10, "seconds");
                    } else if (f.inputType == "AUTO_INCREMENT_NUMBER") {
                      if (f.considerExcelData === false) {
                        if (isFirstIterationForIncrement) {
                          this.startingNum = f.increStartingNum;
                          isFirstIterationForIncrement = false;
                        }
                        let str = f.increStartingNum;
                        let zeroCount = 0;
                        for (let i = 0; i < str.length; i++) {
                          if (str[i] === "0") {
                            zeroCount++;
                          } else {
                            break;
                          }
                        }
                        f.increStartingNum = parseInt(f.increStartingNum);
                        data[e] =
                          f.increPrefix +
                          "0".repeat(zeroCount) +
                          f.increStartingNum;
                        f.increStartingNum = f.increStartingNum + 1;
                        f.increStartingNum = f.increStartingNum?.toString();
                        f.increStartingNum =
                          "0".repeat(zeroCount) + f.increStartingNum;
                        obj[f.key + "_info"] = {
                          number: this.startingNum ? this.startingNum : "",
                          prefix: f.increPrefix,
                        };
                      } else {
                        data[e] = data[e]?.toString();
                        let [prefix, value] = this.findValidDataWithSeperator(
                          data[e]
                        );
                        if (prefix === "" && value === null) {
                          data[e] = "";
                        } else {
                          data[e] = prefix + value;
                        }
                        obj[f.key + "_info"] = {
                          number: f.increStartingNum,
                          prefix: f.increPrefix,
                        };
                      }
                    } else if (f.inputType == "TIME") {
                      data[e] = this.convertTimeToDate(data[e]);
                    }
                    obj[f.template_filed_id] = data[e];
                    rowObj[f.template_id] = {
                      ...rowObj[f.template_id],
                      ...obj,
                    };
                  }
                });
              });
              tempData.push(rowObj);
            });
            this.templatesData = tempData;
            this.$notify({
              title: "Success",
              message: "Successfully added the templates to entity",
              type: "success",
            });
            this.tableLoading = false;
            this.active = this.active + 1;
          } else {
            this.$notify({
              title: "Error",
              message: "Error in fetching the data",
              type: "error",
            });
            this.isSuccessFullyGoToPreview = false;
          }
        } else {
          this.$notify({
            title: "Error",
            message: "Failed to add the templates",
            type: "error",
          });
          this.isSuccessFullyGoToPreview = false;
        }
      } else {
        this.$notify({
          title: "Error",
          message: "Please add at least one template",
          type: "error",
        });
        this.isSuccessFullyGoToPreview = false;
      }
    },
    convertTimeToDate(timeString) {
      let today = new Date();
      let [hours, minutes, seconds] = timeString.split(":");
      today.setHours(parseInt(hours ? parseInt(hours) : 0));
      today.setMinutes(parseInt(minutes ? parseInt(minutes) : 0));
      today.setSeconds(seconds ? parseInt(seconds) : 0);
      return today;
    },
    handleSizeChangeForPreviewData(val) {
      this.pageSizeForPreviewData = val;
    },
    handleCurrentChangeForPreviewData(val) {
      this.currentPageForPreviewData = val;
    },
    async importMappedExcelFileData() {
      //importMappedDataVersion
      let mappedRows = [];
      if (this.mappedFieldsRows) {
        let relationship = [];
        this.mappedFieldsRows.forEach((row) => {
          if (row && row.template_id && row.template_filed_id) {
            let field = (this.currentEntityAllFields || []).find((fie) => {
              if (
                fie &&
                fie.key &&
                fie.template_id &&
                fie.template_id == row.template_id &&
                fie.key == row.template_filed_id
              ) {
                this.mappedFieldsRows[
                  this.mappedFieldsRows.indexOf(row)
                ].inputType = fie.inputType;
                return true;
              } else {
                return false;
              }
            });
            if (field && field.inputType && field.inputType === "ENTITY") {
              let relationObj = {
                parent_entity_id: this.getEntityDataById._id, //employee id
                child_entity_id: field.entity_id, //
                child_relation_title: field.label,
                child_relation_type: "ONE",
                parent_relation_title: this.getEntityDataById.name,
                parent_relation_type: "MANY",
                is_bidirectional: true,
                owner_type: "PARENT",
                representation: "FIELD",
                field_id: field ? field._id : "",
              };
              relationship.push(relationObj);
            }
            mappedRows.push(row);
          }
        });
        //add relationships
        if (relationship.length > 0) {
          let params = {
            relationshipData: relationship,
            parent_entity_id: this.getEntityDataById._id,
          };
          await this.$store.dispatch(
            "entityRelationships/addEntityRelationships",
            params
          );
          if (
            this.getEntityRelationshipsAddStatus &&
            this.getEntityRelationshipsAddStatus === true
          ) {
            this.$notify.success({
              title: true,
              message: "Entity relationships added successfully",
            });
          } else {
            this.$notify.error({
              title: "Error",
              message: "Error in adding relationships",
            });
            return;
          }
        }
        // let finalArr=[];
        // mappedRows.forEach((row)=>{
        //   let standrdTemp=this.getAllCompanyTemplatesData.find((temp)=>{
        //     if(temp && row && temp.type==='STANDARD' && temp._id && row.template_id && temp._id==row.template_id){
        //       return true;
        //     }
        //     else{
        //       return false;
        //     }
        //   })
        //   if(standrdTemp){
        //     finalArr.push(row);
        //   }
        // })
        // mappedRows.forEach((row)=>{
        //   let isFound=finalArr.indexOf((header)=>{
        //     if(header && header.template_id && header.template_filed_id && header.templateId==row.template_id && header.template_filed_id==row.template_filed_id){
        //       return true;
        //     }
        //   })
        //   if(isFound<0){
        //     finalArr.push(row);
        //   }
        // })
        /*this is regarding contact details template */
        // let params = {
        //   mapped_fields: mappedRows,
        //   fileName: this.getstoreUploadedFileStatus,
        //   entityId: this.getEntityCreatedData._id,
        // };
        let params = {
          data: this.successedExcelImportData,
          entityId: this.currentEntity._id,
        };
        this.importDialogueVisible = true;
        await this.$store.dispatch("entities/importMappedDataVersion", params);
        this.importDialogueVisible = false;
        if (
          this.getimportMappedDataStatusUpdatedVersion &&
          this.getimportMappedDataStatusUpdatedVersion.status &&
          this.getimportMappedDataStatusUpdatedVersion.message
        ) {
          this.$notify.success({
            title: "Success",
            message: "Import process has been initiated",
          });
          this.importDialogueVisible = false;
          this.mappingStatusDialogVisible = true;
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error in importing data",
          });
          this.importDialogueVisible = false;
          this.$router.push("/entity");
        }
      }
    },
    gotoDashboard() {
      this.mappingStatusDialogVisible = false;
      this.$router.push("/entity");
      // this.$router.push(`/entity/${this.currentEntity._id}`);
    },
    checkIsNewTemplate(row) {
      if (!row.template_id && !row.template_id?.includes("newTemplate")) {
        row.isPrimaryField = false;
        row.isRequired = false;
        return true;
      }
      return false;
    },
    checkIsRequiredDisabled(row) {
      if (!row.template_id?.includes("newTemplate")) {
        row.isRequired = false;
        return true;
      }
      return false;
    },

    ValidateInputFields(row, index) {
      this.incrementFieldRowIndex = index;
      if (row && row.inputType === "AUTO_INCREMENT_NUMBER") {
        this.dialogVisible = true;
        this.increPrefix = "";
        this.increStartingNum = null;
        this.considerExcelData = false;
      } else if (row && row.inputType === "ENTITY_VARIABLE") {
        this.entityVariableField = null;
        let entityFields = this.getExcelFileHeaders.filter((e) => {
          if (
            e &&
            (e.inputType === "ENTITY" ||
              e.entityVariableInfo.split("#")[2] == "ENTITY") &&
            e.template_id.includes("newTemplate") &&
            row.template_id === e.template_id
          ) {
            return true;
          } else {
            return false;
          }
        });
        this.currentTemplateEntityFields = entityFields ? entityFields : [];
        this.entityVariableField = row;
        this.entityVarDialogVisible = true;
      }
    },
    getSelectedEntityFieldValue() {
      this.selectedEntityAllFields = [];
      if (
        this.entityVariableField.relationship_key !== "" &&
        this.entitiesList
      ) {
        let entityField = this.entitiesList.find((e) => {
          if (
            e &&
            e._id &&
            this.entityVariableField["relationship_key"].split("#")[0] === e._id
          ) {
            return true;
          } else {
            return false;
          }
        });
        if (entityField && entityField.templates) {
          entityField.templates.forEach((te) => {
            let template = this.getAllCompanyTemplatesData.find((t) => {
              if (t && t._id === te.template_id) {
                return true;
              } else {
                return false;
              }
            });
            if (
              template &&
              template.sections[0] &&
              template.sections[0].fields
            ) {
              template.sections[0].fields.forEach((fie) => {
                fie.template_id = template._id;
                fie.template_name = template.name;
                this.selectedEntityAllFields.push(fie);
              });
            }
          });
        }
      }
    },
    setPropertiesForEntityVariable() {
      this.getExcelFileHeaders.map((header) => {
        if (
          header &&
          header.inputType == "ENTITY_VARIABLE" &&
          header.excel_field == this.entityVariableField.excel_field
        ) {
          header.entityVariableInfo =
            this.entityVariableField["entityVariableInfo"];
          header.relationship_key =
            this.entityVariableField["relationship_key"];
        }
      });
      this.entityVarDialogVisible = false;
      // let parentField=this.selectedEntityAllFields.find((fie)=>{
      //   if(fie && fie._id===this.entityVarValue.split("#")[0]){
      //     return true;
      //   }
      //   else{
      //     return false;
      //   }
      // })
      // let parent=this.getExcelFileHeaders.find((header)=>{
      //   if(header && header.inputType==='ENTITY' && header.entity_id===this.entityFieldValue){
      //     return true;
      //   }
      //   else{
      //     return false;
      //   }
      // })
      // if(parentField && parent){
      //   this.getExcelFileHeaders[this.incrementFieldRowIndex].global_variable_entity_field=parentField.template_id+"#"+parentField.key;
      //   this.getExcelFileHeaders[this.incrementFieldRowIndex].global_variable_entity_field_template_id=parentField.template_id;
      //   this.getExcelFileHeaders[this.incrementFieldRowIndex].global_variable_entity_parent=parent.excel_field;
      //   this.getExcelFileHeaders[this.incrementFieldRowIndex].global_variable_entity_select_type_field_key=parentField.key;
      //   this.getExcelFileHeaders[this.incrementFieldRowIndex].relationship_key=parent.excel_field;
      //   this.getExcelFileHeaders[this.incrementFieldRowIndex].input_type=parentField.inputType;
      //   this.getExcelFileHeaders[this.incrementFieldRowIndex].selected_enity_field_data={
      //     inputType:parentField.inputType,
      //     input_type:parentField.input_type,
      //     key:parentField.template_id+"#"+parentField.key,
      //     label:parentField.template_name+"-"+parentField.label,
      //     template_id:parentField.template_id
      //   }
      //   this.entityVarDialogVisible=false;
      // }
      // this.entityVarValue = ''
      // this.entityFieldValue = ''
    },
    resetIncrementFields() {
      this.getExcelFileHeaders[this.incrementFieldRowIndex].inputType = "";
      this.getExcelFileHeaders[this.incrementFieldRowIndex].template_id = "";
      this.dialogVisible = false;
    },
    handleCloseIncrementDialog() {
      this.getExcelFileHeaders[this.incrementFieldRowIndex].inputType = "";
      this.getExcelFileHeaders[this.incrementFieldRowIndex].template_id = "";
      this.dialogVisible = false;
    },
    setIncrementProperties() {
      this.getExcelFileHeaders[this.incrementFieldRowIndex].increPrefix =
        this.increPrefix;
      this.getExcelFileHeaders[this.incrementFieldRowIndex].increStartingNum =
        this.increStartingNum;
      this.getExcelFileHeaders[this.incrementFieldRowIndex].considerExcelData =
        this.considerExcelData;
      this.dialogVisible = false;
    },
    checkIsImage(row) {
      let isFound = this.getAllCompanyTemplatesData.find((temp) => {
        if (
          temp &&
          temp._id &&
          row &&
          row.template_id &&
          row.template_id == temp._id
        ) {
          return true;
        } else {
          return false;
        }
      });
      if (isFound) {
        if (isFound && isFound.sections[0].fields) {
          isFound.sections[0].fields.forEach((fie) => {
            fie.template_id = isFound._id;
          });

          let isFieldFound = isFound.sections[0].fields.find((fie) => {
            if (
              fie &&
              fie.template_id &&
              fie.key &&
              row.template_id == fie.template_id &&
              row.template_filed_id == fie.key
            ) {
              return true;
            } else {
              return false;
            }
          });
          if (isFieldFound && isFieldFound.inputType === "IMAGE") {
            return true;
          } else {
            return false;
          }
        }
      }
    },
    checkInputType(row) {
      if (row && row.inputType === "ENTITY") {
        this.isEntityField = true;
      }
    },
    removeExcelFile() {
      this.isExcelFileuploaded = false;
      this.excelFile = null;
    },
  },
};
</script>
<style lang="scss">
.match_upload_button {
  height: 10vh;
  border: 1.5px solid #f754a2;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 10px 10px !important;
  margin-left: 10px !important;
  height: 35px;
  color: #f754a2;
  background-color: white;
}

.data-import-btn {
  border-width: 1px;
  border-radius: 2px;
  background-color: #ffffff;
  border-color: #f754a2;
  border-style: solid;
  font-size: 14px !important;
}

.division {
  border: 1px solid #d7eefc;
  padding: 5px;
  margin: 3px;
}

.template-note {
  background-color: #f2f8fc;
  border-radius: 3px;
  height: 50px;
}

.field-icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.import-loading-gif {
  height: 25vh;
  width: 15vw;
}

.entity-add-btn {
  height: 40px;
  font-size: 21px;
  font-weight: 10px;
  margin-left: 11px;
}

.dashboard-btn {
  background-color: #f754a2;
  border-width: 0px;
  color: white;
  border-radius: 2px;
  font-size: 16px;
}

.uploaded-file-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  width: 36vw;
  height: 8vh;
}

.flex-grow-1 {
  flex-grow: 1;
}
.popUp-content > * {
  margin-bottom: 10px;
}
.SaveBtn {
  margin: 10px 0px;
}
.dialog-footer {
  float: right;
}
</style>
